/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { graphql } from 'gatsby';
import { jsx, css } from '@emotion/core';
import PropTypes from 'prop-types';
import SEO from '../components/seo';

import PageContainer from '../components/page-container';
import AddressCard from '../components/address-card';
import Title from '../components/title';
import SubTitle from '../components/subtitle';
import Content from '../components/content';
import ImageRow from '../components/image-row';

const CancelPolicy = props => {
  const { policy } = props;

  return (
    <div>
      <SubTitle text="Reservations/Cancelation Policy" />
      <p>{policy}</p>
    </div>
  );
};

CancelPolicy.propTypes = {
  policy: PropTypes.string,
};

const ItemsToBring = props => {
  const { desc, items } = props;

  return (
    <div>
      <SubTitle text="What to bring?" />
      <p>{desc}</p>
      <ul css={tw`pl-6`}>
        {items.map((item, k) => (
          <li css={tw`leading-normal`} key={k}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

ItemsToBring.propTypes = {
  desc: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
};

const tableHeading = css`
  ${tw`p-3`};
`;

const GuidePricing = props => {
  const { title, pricing } = props;

  return (
    <table css={tw`mt-4 text-center`}>
      <caption css={tw`uppercase text-red`}>{title}</caption>
      <thead>
        <tr>
          <th css={tableHeading}>Anglers</th>
          <th css={tableHeading}>Half Day</th>
          <th css={tableHeading}>Full Day</th>
        </tr>
      </thead>
      <tbody>
        {pricing.map((trip, k) => (
          <tr key={k}>
            <td>{trip.anglers}</td>
            <td>{trip.half > 0 ? `$${trip.half}` : 'n\\a'}</td>
            <td>{trip.full > 0 ? `$${trip.full}` : 'n\\a'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

GuidePricing.propTypes = {
  title: PropTypes.string,
  pricing: PropTypes.arrayOf(
    PropTypes.shape({
      anglers: PropTypes.number,
      half: PropTypes.number,
      full: PropTypes.number,
    })
  ),
};

const GuidedTripsPage = ({ data }) => {
  const { edges: images } = data.tripImages;
  const {
    title,
    seo,
    trips,
    cancelationPolicy,
    bringAlongDescription,
    bringAlongItems,
  } = data.page.frontmatter;
  const { html: content } = data.page;

  return (
    <PageContainer>
      <SEO title={seo.title} description={seo.description} />
      <div className="section">
        <Title text={title} />
        <ImageRow images={images} />
        <div className="columns">
          <div className="column is-two-thirds">
            <SubTitle text="Guided Fly Fishing Trips" />
            <Content html={content} />
          </div>
          <div className="column">
            <SubTitle text="How Much Does it Cost?" />
            <GuidePricing title="Wade Trips" pricing={trips.pricing.wade} />
            <GuidePricing title="Float Trips" pricing={trips.pricing.float} />
            <GuidePricing title="Lake Trips" pricing={trips.pricing.lake} />
            <div css={tw`mt-4 p-4 pl-0 text-base leading-tight`}>
              {trips.description}
            </div>
            <div css={tw`mt-0 pb-4 pr-4 text-base leading-tight`}>
              {trips.lakeDescription}
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="columns">
          <div className="column is-half" css={tw`md:pr-10`}>
            <CancelPolicy policy={cancelationPolicy} />
            <AddressCard />
          </div>
          <div className="column is-half" css={tw`md:pl-10`}>
            <ItemsToBring
              desc={bringAlongDescription}
              items={bringAlongItems}
            />
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

GuidedTripsPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        seo: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }),
        CancelPolicy: PropTypes.string,
        bringAlongDescription: PropTypes.string,
        bringAlongItems: PropTypes.array,
        trips: PropTypes.shape({
          description: PropTypes.string,
          lakeDescription: PropTypes.string,
          pricing: PropTypes.shape({
            wade: PropTypes.arrayOf(
              PropTypes.shape({
                anglers: PropTypes.number,
                half: PropTypes.number,
                full: PropTypes.number,
              })
            ),
            float: PropTypes.arrayOf(
              PropTypes.shape({
                anglers: PropTypes.number,
                half: PropTypes.number,
                full: PropTypes.number,
              })
            ),
            lake: PropTypes.arrayOf(
              PropTypes.shape({
                anglers: PropTypes.number,
                half: PropTypes.number,
                full: PropTypes.number,
              })
            ),
          }),
        }),
      }),
    }),
  }),
};

export default GuidedTripsPage;

export const query = graphql`
  query GuideTripsPageQuery {
    page: markdownRemark(frontmatter: { title: { eq: "Guided Trips" } }) {
      html
      frontmatter {
        title
        metaDescription
        cancelationPolicy
        bringAlongDescription
        bringAlongItems
        seo {
          title
          description
        }
        trips {
          description
          lakeDescription
          pricing {
            wade {
              anglers
              half
              full
            }
            float {
              anglers
              half
              full
            }
            lake {
              anglers
              half
              full
            }
          }
        }
      }
    }
    tripImages: allFile(
      limit: 3
      filter: { relativePath: { regex: "images/guided-trips/.*.jpg/" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`;
