/* eslint no-unused-vars: 0 */
/* global tw */
import React from 'react';
import { jsx, css } from '@emotion/core';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const imgHide = css`
  ${tw`hidden md:block`};
`;

const imgShow = css`
  ${tw`block`};
`;

const ImageRow = props => {
  const { images } = props;

  return (
    <div className="columns" css={tw`mb-0 pb-0 md:pb-4`}>
      {images.map((image, k) => (
        <div className="column" key={k} css={k > 0 ? imgHide : imgShow}>
          <div>
            <Img fluid={image.node.childImageSharp.fluid} css={tw`rounded`} />
          </div>
        </div>
      ))}
    </div>
  );
};

ImageRow.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object),
};

export default ImageRow;
